.card-content {
  position: relative;
  padding-bottom: 50px;
}

.button-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
