.title-wrapper {
  margin: 40px 0 28px;
}

.title-wrapper > h2 {
  margin-bottom: 12px;
}

.subheader-wrapper {
  margin: 16px auto 8px;
}

.spacer {
  height: 24px;
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.copy-icon {
  margin-left: 0.2rem;
  cursor: pointer;
  padding: 0.1rem 0.3rem;
  border-radius: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
