@mixin atSmall {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin atMedium {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin atLarge {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin atExtraLarge {
  @media (min-width: 1440px) {
    @content;
  }
}
