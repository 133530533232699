@import 'styles/globals.scss';

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-at-tablet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.detail-pair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    flex-direction: column;
  }
}

.space-y-sm {
  & > * + * {
    margin-top: 0.75rem;
  }
}

.space-y-md {
  @media screen and (min-width: 768px) {
    & > * + * {
      margin-top: 1.5rem;
    }
  }
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80px;

  @media screen and (min-width: 512px) {
    padding: 0 1rem;
  }

  & > * + * {
    margin-left: 3rem;
  }
}

.centered-spaced {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  & > * + * {
    margin-left: 0.5rem;
  }
}

.expander {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.detail-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.px-8 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.text-subdued {
  color: #bdc1cc;
}

.link-text {
  text-decoration: none;
  color: #2c6ecb;
}

.disabled-text {
  text-decoration: none;
  color: gray;
  pointer-events: none;
}

.max-w-sm {
  max-width: 300px;
}

.scan-width {
  min-width: 10rem;
}
