.mt-4 {
  margin-top: 1rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 100%;
  margin: auto;
}

.no-hover {
  pointer-events: none;
}
