@import 'styles/globals.scss';

.expanded-card {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  gap: 1.25rem;
}

.blockchain-width {
  width: 40px;
}

.royalty-split-contracts-card {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.local-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.royalty-split-row-name {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.royalty-split-row-additional-info {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.royalty-split-expanded-card-items {
  display: flex;
  gap: 5rem;
}

.recipients {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.scan-link {
  text-decoration: none;
  color: #2c6ecb;
}

.icon-scan-link {
  display: flex;
  gap: 0.25rem;
}

.chain-info {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
}
