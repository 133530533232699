@import 'styles/mediaQueries.scss';
@import './margins.scss';
@import './paddings.scss';

.width-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.pl-1 {
  padding-left: 1rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  margin: auto;
}

.mw-12 {
  min-width: 12rem;
}
