@import 'styles/globals.scss';

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-at-tablet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.row-half {
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.detail-pair {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.space-y-sm {
  & > * + * {
    margin-top: 0.75rem;
  }
}

.space-y {
  margin-top: 0.75rem;
}

.space-y-md {
  @media screen and (min-width: 768px) {
    & > * + * {
      margin-top: 1.5rem;
    }
  }
}

.space-y-md-mobile {
  margin-top: 1.5rem;
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
}

.details {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * + * {
    margin-right: 3rem;
  }
}

.centered-spaced {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  & > * + * {
    margin-left: 0.5rem;
  }
}

.notFoundWrapper {
  display: flex;
  padding: 12px 20px;
  justify-content: flex-start;
  align-items: center;
}

.notFoundWrapper h3 {
  margin-left: 10px;
}

.expander {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.expander > *:not(:last-child) {
  margin-right: 2rem;
}

.expander > *:first-child {
  margin-right: 5rem;
}

.detail-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.px-8 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.text-subdued {
  color: #bdc1cc;
}

.link-text {
  text-decoration: none;
  color: #2c6ecb;
}

.column {
  display: flex;
  flex-direction: column;
}

.max-w-sm {
  max-width: 300px;
}

.space {
  display: flex;
  justify-content: space-between;
}

.block {
  display: block;
}

.hidden {
  visibility: hidden;
}
