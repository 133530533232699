.ExtraTightLayout {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.ExtraTightLayout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
