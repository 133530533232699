/* This styles were taken from Polaris to emulate the card styling for pricing */

.pricingCard {
  background-color: var(--p-surface, #ffffff);
  box-shadow: var(
    --p-card-shadow,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  );
  outline: transparent solid 0.1em;
  padding: 1.5em 1.5em 1.875em 1.5em;
  width: 100%;
}

.pricingCardBadge {
  height: 22px;
  margin-top: 1em;
  margin-bottom: 1rem;
}

.pricingCardActions {
  margin-top: 0.75em;
}

.featured {
  border-color: var(--p-action-primary, #008060);
  border-width: 2px;
  border-top-width: 10px;
  border-style: solid;
}

.caption {
  border-color: var(--p-border-subdued);
  border-width: 1px;
  border-style: solid none;
  padding: 1em 0;
  margin: 1em 0;
}

@media (min-width: 30.625em) {
  .pricingCard {
    border-radius: var(--p-border-radius-wide, 3px);
    margin-top: 10px;
  }

  .featured {
    margin-top: 0;
  }
}

.planFooter :global(.Polaris-FooterHelp) {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0px;
  padding-top: 5px;
}

.empty :global(.Polaris-List__Item) {
  visibility: hidden;
}
