.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px;
}

.icon > svg {
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
}

.info {
  margin-top: 24px;
}
