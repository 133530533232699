.container {
  border-radius: 3px;
  border: solid;
  height: 86px;
  padding-left: 19px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px 0px #3f3f4426;
  box-shadow: 0px 0px 0px 0px #3f3f440d;
  border-color: #3f3f440d;
}

.container :global(.Polaris-Choice__Label) {
  margin-top: -2px;
}

.container > * {
  width: 100%;
}

.bg-surface-selected {
  background-color: var(--p-surface-selected);
}

.w-full {
  width: 100%;
}

.disabled-surface {
  background-color: #fafbfb;
}

.hover {
  cursor: pointer;
}
