.FileUpload {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.FileUploadPreview {
  max-width: 100%;
}

@media (min-width: 500px) {
  .FileUploadPreview {
    max-width: 300px;
    width: fit-content;
    height: auto;
  }
}
