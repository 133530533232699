.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0 1.75rem;
}

.title-wrapper > h2 {
  margin-bottom: 12px;
}

.subheader-wrapper {
  margin: 0 auto 12px;
}

.spacer {
  height: 24px;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  margin: auto;
}

.bulletList li {
  min-height: 30px;
}
