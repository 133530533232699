.inner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem;
  background-color: #fafbfb;
  border-radius: var(--p-border-radius-wide, 3px);
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
