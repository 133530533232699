.asset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

.bordered {
  border: 1px solid #c9cccf;
  border-radius: 4px;
}
