@import 'styles/globals.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin: 0 auto;
  padding: 64px 0 64px;
  height: 250px;

  @include atMedium {
    width: 300px;
  }

  & > svg {
    margin: 0 auto;
  }

  & > button {
    width: 180px;
    margin: 0 auto;
  }
}
