.table-customisations {
  border-top: 1px solid rgba(225, 227, 229, 1);

  & tbody {
    border-radius: var(--p-border-radius-wide, 3px);
  }

  & thead {
    display: none !important;
  }

  & tr,
  td,
  th {
    background: white !important;
  }
}

.edit-button {
  position: relative;
  top: 10px;
  z-index: 10;
}

.custom-row {
  cursor: pointer;
  & a {
    text-decoration: none;
  }
}

.highlighted-text {
  color: #007ace !important;
}
