.getting_started_card {
  --p-surface: #f3f3fd;
}
.getting_started_card:hover {
  cursor: pointer;
  --p-surface: #eeeef8;
}
.embedded_video {
  width: 100%;
  height: 70vh;
}
