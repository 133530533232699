.table-customisations {
  margin-top: 16px;

  & thead {
    display: none !important;
  }

  & tr,
  td,
  th {
    border-top: 1px solid rgba(225, 227, 229, 1);
    background: white !important;
  }
}

.edit-button {
  position: relative;
  z-index: 10;
}
