.my-8 {
  margin: 2rem 0;
}

.center {
  text-align: center;
}

.divider {
  border-top: 1px solid var(--p-surface-disabled);
}

.mt-4 {
  margin-top: 1rem;
}

.resourceProduct .Polaris-Stack__Item {
  margin-top: -2rem;
}

.align-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
