.container {
  padding: 0rem 2rem 2rem 0;
}

.helpText {
  margin-left: 20px;
}

.wrap {
  display: inline-flex;
  width: 95%;
  gap: 1rem;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  align-items: center;
}

.listItem {
  width: 100%;
}

.thumbnail :global(.Polaris-Thumbnail) {
  display: inline-block;
  height: 100%;
  border: unset;
}

.thumbnail {
  height: 100%;
}

.asset {
  height: 4rem;
  max-width: 5rem;
  cursor: pointer;
  border-radius: 2px;
}

.uploadInfo {
  text-align: left;
  padding-left: 5px;
}

.inner {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.dropzone {
  text-align: center;
}

.upload {
  color: var(--p-text-subdued);
  font-size: 12px;
  max-width: 365px;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.textWrap {
  margin-top: 30px;
  margin-bottom: 4px;
  color: #2c6ecb;
}

.subtitleWrap {
  margin-top: -14px;
}

.spinner {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin: auto;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.uppercase {
  text-transform: uppercase;
}
