.numberBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  margin: 1rem 0;
  margin-bottom: 0.5rem;
  background-color: #6e58e3;
  border-radius: 0.25rem;
  color: white;
}

.instructionSection {
  padding-bottom: 1rem;
}

.instruction {
  margin: 1rem;
}

.intructionTitle {
  font-size: 12px;
  font-weight: 600;
}
