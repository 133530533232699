.box-link {
  padding: 1rem;
  cursor: pointer;
  position: relative;
  flex: 1;
  border-radius: var(--p-border-radius-wide);
}

.box-link-contents {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

.box-link-primary {
  background-color: var(--p-action-primary);
  color: white;
  fill: white;
  outline: 0.0625rem solid #3f3f440d;
  box-shadow: 0 1px 3px 0 #3f3f4426;
}
.box-link-primary:hover {
  background-color: var(--p-action-primary-hovered);
}

.box-link-surface:hover {
  background-color: var(--p-surface-depressed);
}

.box-link-overlay {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: #2c6ecb;
}

.box-link-surface {
  background-color: white;
  outline: 0.0625rem solid transparent;
  box-shadow: var(--p-card-shadow);
}
