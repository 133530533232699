.badge {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.container {
  padding: 1rem 0rem 1rem 0;
  margin-top: 1rem;
}

.copy-icon {
  margin-left: 0.2rem;
  cursor: pointer;
  padding: 0.1rem 0.3rem;
  border-radius: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
