.mb-8 {
  margin-bottom: 2rem;
}

.heading-spacing {
  margin: 2rem 0 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.heading-link {
  position: relative;
  top: 0.5rem;
}
