@import 'styles/globals.scss';

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  margin: auto;
}
