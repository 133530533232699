.wrapper {
  display: flex;
  flex-direction: row;
}

.blockchain-link {
  text-decoration: none;
  color: #2c6ecb;
  word-break: break-word;
}

.hidden-input {
  position: absolute;
  height: 15px;
  width: 15px;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
}

.copy-icon {
  margin-left: 0.2rem;
  cursor: pointer;
  padding: 0.1rem 0.3rem;
  border-radius: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
