@import 'styles/globals.scss';

.wrapper {
  margin: 40px 0;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @include atMedium {
    flex-direction: row;
    justify-content: space-around;
    padding: 24px;
  }
}

.info {
  flex: 1.2;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;

  @include atMedium {
    align-items: flex-start;
    margin-top: -65px;
  }
}

.spacer {
  margin: 34px 0;
  text-align: center;
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }

  span:not(:last-child) {
    margin-bottom: 24px;
  }

  svg {
    width: 30px;
    margin-right: 3px;
    margin-left: -5px;
  }

  @include atMedium {
    text-align: left;
  }
}
