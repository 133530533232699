.radioContainer {
  border-radius: 3px;
  height: 86px;
  padding-left: 19px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px 0px #3f3f4426;
  box-shadow: 0px 0px 0px 0px #3f3f440d;
}

.radioContainer :global(.Polaris-Choice__Label) {
  margin-top: -2px;
}

.planNotice {
  margin-top: 20px;
}

.radioSelected {
  background-color: var(--p-surface-selected);
}

.birthYear :global(.Polaris-TextField__Segment) {
  display: none;
}

.foundedYear :global(.Polaris-TextField__Segment) {
  display: none;
}

:global(.Polaris-Modal-Dialog):has(.container) {
  max-width: 612px;
  margin: 0 auto;

  :global(.Polaris-Modal-CloseButton) {
    padding: 26px;
  }
}

.hidden {
  display: none;
}
