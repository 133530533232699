.title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0 1.75rem;
}

.title-wrapper > h2 {
  margin-bottom: 12px;
}

.subheader-wrapper {
  margin: 16px auto 8px;
}

.spacer {
  height: 24px;
  width: 100%;
}
