.tabsButton div button {
  width: 120px;
}

@media only screen and (max-width: 350px) {
  .tabsWrapper {
    display: block;
  }
  .tabsButton {
    visibility: hidden;
  }
}

/* When there is a polaris modal on screen allow it to appear over the intercom button */
@media (max-width: 770px) {
  body:has(.Polaris-Modal-Dialog__Container) .intercom-lightweight-app {
    z-index: 0;
  }
}
