@import 'styles/globals.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  margin: 0 auto;
  padding: 6.75rem 0;

  @include atMedium {
    width: 22rem;
  }

  & > h2 {
    margin-bottom: 1rem;
  }

  & > span {
    margin-bottom: 1.5rem;
  }

  & > button {
    width: 11.25rem;
    margin: 0 auto;
  }
}
