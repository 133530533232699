.row {
  display: flex;
  gap: 1rem;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  width: 100%;
  min-height: 250px;
  box-shadow: 0 0 0 0 #3f3f440d;
  border-color: #3f3f440d;
  border-width: var(--p-border-width-1);
  border-style: solid;
  border-radius: var(--p-border-radius-base);
  cursor: pointer;
}

.button--selected {
  background-color: var(--p-surface-selected);
}

.buttonImage {
  object-fit: contain;
}
