@import 'styles/globals.scss';

.title-wrapper {
  margin: 40px 0 28px;
}

.box-link-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: stretch;
}

@media screen and (min-width: 640px) {
  .box-link-container {
    flex-direction: row;
  }
}

.scroller {
  display: flex;
  overflow-x: scroll;
  gap: 1rem;
  scroll-snap-type: x mandatory;
}
.helpGuideCard {
  width: 45%;
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.helpGuideCardImage {
  width: 100%;
  height: auto;
}

.stretchedBox {
  padding: 16px; /* Optional: Adjust padding for better spacing */
}

.subtitle {
  color: #6d6d6d;
  font-size: 14px;
  margin: 0;
}
